import React, { useLayoutEffect, useMemo } from 'react'
import styled from 'styled-components'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import FocusTrap from 'react-focus-trap'

import { theme } from 'styles/themes'
import Translation from 'utils/Translation'

import { useLayout } from '../../context'
import MobileOptionItem from './MobileOptionItem'

const Wrapper = styled.div`
  position: fixed;
  top: ${theme.Sizes.mobileHeaderHeight};
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${theme.Indexes.mobileMenu};
  justify-content: center;
  align-items: center;
  background-color: white;
  overflow-y: auto;
  padding-bottom: 24px;
`

const MOBILE_MENU_ID = 'mobileMenu'

const MobileMenu = ({ headerDropdowns = [] }) => {
  const { isMobileMenuOpen } = useLayout()

  const mobileOptions = useMemo(() => {
    const configurableHeaderDropdowns = headerDropdowns.reduce(
      (accum, { title, items = [] }) => {
        accum.push({ isSectionTitle: true, label: title })
        items.map(item => accum.push(item))

        return accum
      },
      [],
    )

    return [
      {
        isSectionTitle: true,
        label: <Translation id="header.secmarket" />,
      },
      // ...MOBILE_PLATFORM_LINKS,
      ...configurableHeaderDropdowns,
    ]
  }, [headerDropdowns])

  useLayoutEffect(() => {
    if (isMobileMenuOpen) {
      const mobileMenuWrapper = document.getElementById(MOBILE_MENU_ID)
      disableBodyScroll(mobileMenuWrapper)
    }

    return () => clearAllBodyScrollLocks()
  }, [isMobileMenuOpen])

  if (!isMobileMenuOpen) return null

  return (
    <FocusTrap active style={{ outline: 'none' }}>
      <Wrapper id={MOBILE_MENU_ID}>
        {mobileOptions.map((option, i) => (
          /* eslint-disable react/no-array-index-key */
          <MobileOptionItem key={i} {...option} />
        ))}
      </Wrapper>
    </FocusTrap>
  )
}

export default MobileMenu
